import { useState, useEffect, useCallback } from "react";
import { useDispatch, connect } from "react-redux";
import { getUsers } from "@sharecode/common/redux/actions/user";
import { getAdminUsersByBrand } from "@sharecode/common/redux/actions/brand";
import Table from "examples/Tables/Table";
import SuiBadge from "components/SuiBadge";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import { useRole } from "routes/ProtectedRoutes";
import { useSearchParams, useLocation } from "react-router-dom";
import ActionCell from "../ActionCell";
import DefaultCell from "../DefaultCell";
import { IS_ADMIN_VALUE, IS_MANAGER_BRAND_VALUE, IS_MANAGER_SHOP_VALUE } from "@sharecode/common/redux/actions/actionTypes";
import { Oval } from "@agney/react-loading";
import moment from "moment";
import SuiAvatar from "components/SuiAvatar";
import { Select, MenuItem } from "@mui/material";
import { debounce } from "lodash";
import { clearBrandUsers } from "@sharecode/common/redux/actions/brand";

const TableRequest = (props) => {
  const [columns, setColumns] = useState();
  const [rows, setRows] = useState();
  const role = useRole();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRole, setSelectedRole] = useState("");
  const location = useLocation();
  const brandId = location.state?.brandId;

  const roleMapping = {
    "": "Tous",
    "admin": "Admin",
    "brand_manager": "Brand Manager"
  };

  const fetchUsers = useCallback(
    debounce((page, pagesize, search, roleParam) => {
      setLoading(true);

      if (brandId) {
        // Only fetch brand users if we don't have them yet
        if (!props.brand.brandUsers) {
          dispatch(getAdminUsersByBrand(brandId))
            .then((response) => {})
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      } else {
        dispatch(getUsers(page, pagesize, search, roleParam))
          .then((response) => {})
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }, 300),
    [dispatch, brandId, props.brand.brandUsers]
  );

  useEffect(() => {
    const page = searchParams.get("page") || 1;
    const pagesize = searchParams.get("pagesize") || 100;
    const search = searchParams.get("search") || '';
    const roleParam = selectedRole || '';

    // Only fetch if we don't have brand users data when viewing brand users
    if (brandId && !props.brand.brandUsers) {
      fetchUsers(page, pagesize, search, roleParam);
    } else if (!brandId) {
      fetchUsers(page, pagesize, search, roleParam);
    }
  }, [window.location.search, fetchUsers, searchParams, selectedRole, brandId, props.brand.brandUsers]);

  useEffect(() => {
    // Get the appropriate data source based on whether we're viewing brand users
    const usersData = brandId ? props.brand.brandUsers?.results : props.user.results;

    if (usersData && usersData.length > 0) {
      let objColumns = [
        { name: "Photo", align: "center" },
        { name: "Nom", align: "center" },
        { name: "Prénom", align: "center" },
        { name: "Email", align: "center" },
        { name: "Date d'inscription", align: "center" },
        { name: "Nb de tickets", align: "center" },
        { name: "Montant moyen", align: "center" },
        { name: "Etat", align: "center" },
      ];

      if (role && [IS_ADMIN_VALUE, IS_MANAGER_BRAND_VALUE, IS_MANAGER_SHOP_VALUE].indexOf(role) > -1) {
        objColumns.push({ name: "Action", align: "center" });
      }
      setColumns(objColumns);

      // Only filter by role if we're not viewing brand users
      const filteredUsers = brandId
        ? usersData
        : (selectedRole ? usersData.filter((user) => user.role === selectedRole) : usersData);

      setRows(
        filteredUsers.map((item) => ({
          Photo: item.picture ? <SuiAvatar src={`${item.picture}`} variant="rounded" /> : "-",
          Nom: <DefaultCell>{item.last_name || "-"}</DefaultCell>,
          Prénom: <DefaultCell>{item.first_name || "-"}</DefaultCell>,
          Email: <DefaultCell>{item.email || item.check_email || "-"}</DefaultCell>,
          "Date d'inscription": (
            <DefaultCell>{moment(item.date_joined).format("DD-MM-YYYY")}</DefaultCell>
          ),
          "Nb de tickets": (
            <DefaultCell>{item.numberoftickets || "0"}</DefaultCell>
          ),
          "Montant moyen": (
            <DefaultCell>{Number(item.averagecart).toFixed(2) + " €" || "0 €"}</DefaultCell>
          ),
          Etat: (
            <SuiBadge
              variant="contained"
              color={item.last_login ? "success" : "error"}
              size="xs"
              badgeContent={item.last_login ? "actif" : "inactif"}
              container
            />
          ),
          ...(role && [IS_ADMIN_VALUE, IS_MANAGER_BRAND_VALUE, IS_MANAGER_SHOP_VALUE].indexOf(role) > -1 && {
            Action: <ActionCell id={item.id} />
          })
        }))
      );
    } else {
      setRows([]);
    }
  }, [props.user.results, props.brand.brandUsers, selectedRole, brandId]);

  useEffect(() => {
    // Cleanup when component unmounts
    return () => {
      dispatch(clearBrandUsers());
    };
  }, [dispatch]);

  return (
    <>
      {!brandId && (
        <SuiBox textAlign="center" marginBottom={3} width={200} marginLeft={2}>
          <SuiTypography htmlFor="select-role" style={{ marginRight: "40px", marginBottom: "10px" }}>
            Filtrer par rôle:
          </SuiTypography>
          <SuiBox display="flex" alignItems="center">
            <Select
              id="select-role"
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
              style={{ padding: "10px", borderRadius: "5px", outline: "none" }}
              displayEmpty
            >
              {Object.entries(roleMapping).map(([value, displayName]) => (
                <MenuItem key={value} value={value}>
                  {displayName}
                </MenuItem>
              ))}
            </Select>
          </SuiBox>
        </SuiBox>
      )}
      {loading ? (
        <SuiBox textAlign="center">
          <Oval width="50" color="info" />
        </SuiBox>
      ) : (
        <>
          {columns && rows && columns.length > 0 && rows.length > 0 ? (
            <Table columns={columns} rows={rows} />
          ) : (
            <SuiBox textAlign="center" marginBottom={5}>
              <SuiTypography variant="h6" fontWeight="medium">
                Aucun utilisateur trouvé
              </SuiTypography>
            </SuiBox>
          )}
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    brand: state.brand,
  };
}

export default connect(mapStateToProps)(TableRequest);
