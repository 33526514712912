//React and libs
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

// Material UI
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// SUI
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import UserCreateUpdate from "examples/Drawers/userCreateUpdate";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setOpenUserDrawer } from "context";

// data
import { IS_ADMIN } from "@sharecode/common/redux/actions/actionTypes";
import { IS_MANAGER_BRAND } from "@sharecode/common/redux/actions/actionTypes";
import { IS_MANAGER_SHOP } from "@sharecode/common/redux/actions/actionTypes";
import { useRole } from "routes/ProtectedRoutes";

//Components
import TableRequest from "./components/TableRequest";
import PageListing from "components/PagesListing";
import { ExportButton } from "components/ExportButton";
import { SizeSelectorFilter } from "components/Filters/SizeSelectorFilter";
import { SearchInputFilter } from "components/Filters/SearchInputFilter";
import { exportTable } from "@sharecode/common/utils/helpers";
import { URL_EXPORT_USERS } from "@sharecode/common/services/constantApi";
import { setSnackbar } from "@sharecode/common/redux/actions/snack";
import { clearBrandUsers } from "@sharecode/common/redux/actions/brand";

function ManageUsers(props) {
  const [controller, controllerDispatch] = useSoftUIController();
  const dispatch = useDispatch();
  const { openUserDrawer } = controller;
  const handleUserDrawerOpen = () => setOpenUserDrawer(controllerDispatch, !openUserDrawer);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sizeSelected, setSizeSelected] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [loadingExport, setLoadingExport] = useState(false);

  const role = useRole();
  const handleChangeSize = (e) => {
    searchParams.set("page", 1);
    searchParams.set("size", e.value);
    searchParams.set("search", "");
    setSizeSelected({ value: e.value, label: e.label });
    setSearchParams(searchParams);
  };

  const searchCtn = (e) => {
    setSearchValue(e.target.value);
    searchParams.set("page", 1);
    searchParams.set("size", searchParams.get("size") ? searchParams.get("size") : 10);
    searchParams.set("search", e.target.value);
    setSearchParams(searchParams);
  };

  const handleUsersExport = () => {
    setLoadingExport(true);

    const keys = ["search"];

    const paramsValues = {};

    keys.forEach((key) => {
      const value = searchParams.get(key);
      if (value) {
        paramsValues[key] = value;
      }
    });

    exportTable({ url: URL_EXPORT_USERS, fileName: "users.xlsx" }, paramsValues)
      .then((response) => {})
      .catch((error) => {
        dispatch(setSnackbar(true, "error", "error", "No entry to export"));
      })
      .finally(() => {
        setLoadingExport(false);
      });
  };

  useEffect(() => {
    const search = searchParams.get("search") || "";
    const size = searchParams.get("size")
      ? { value: searchParams.get("size"), label: searchParams.get("size") }
      : { value: "10", label: "10" };

    setSearchValue(search);
    setSizeSelected(size);
  }, [searchParams]);

  useEffect(() => {
    return () => {
      // Dispatch to both reducers to ensure it's handled
      dispatch(clearBrandUsers());
    };
  }, [dispatch, controllerDispatch]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={2}>
          <Card>
            <SuiBox
              p={2}
              display="flex"
              alignItems="baseline"
              justifyContent="space-between"
              gap={2}
              sx={{ flexDirection: { xs: "column", md: "row" } }}
            >
              <SuiTypography variant="h6">Gestion des comptes</SuiTypography>
              <SuiBox
                display="flex"
                gap={2}
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-start", md: "center" },
                }}
              >
                {role && [IS_ADMIN, IS_MANAGER_BRAND, IS_MANAGER_SHOP].indexOf(role) > -1 && (
                  <SuiButton variant="gradient" color="info" onClick={handleUserDrawerOpen}>
                    <Icon>add</Icon> &nbsp; Ajouter un utilisateur
                  </SuiButton>
                )}

                {/* <ExportButton
                  disabled={props.user.count === 0}
                  handleExport={handleUsersExport}
                  loading={loadingExport}
                /> */}
              </SuiBox>
            </SuiBox>
            <SuiBox
              p={2}
              display="flex"
              justifyContent="space-between"
              gap={4}
              sx={{ flexDirection: { xs: "column", md: "row" } }}
            >
              <SizeSelectorFilter
                sizeValue={sizeSelected}
                handleSizeChange={handleChangeSize}
                totalCount={props.user.count}
              />

              <SearchInputFilter
                placeholder="Recherche..."
                handleSearchChange={searchCtn}
                searchInputValue={searchValue}
              />
            </SuiBox>
            <TableRequest />
          </Card>
          <PageListing data={props.user} />
        </SuiBox>
      </DashboardLayout>
      <UserCreateUpdate />
    </>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ManageUsers);
